<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="metTarefasLista"
          class="elevation-10"
          :search="search"
          :loading="!tarefasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30, -1],
          }"
          :options="{
            itemsPerPage: -1,
          }"
          sortBy="status"
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-format-list-checks</v-icon>
              <v-toolbar-title>Tarefas</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selecionarTarefaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Tarefa</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col
                  cols="auto"
                  md="4"
                  class="ml-1 mb-0"
                  align-self="end"
                  v-if="metMudaBusca()"
                >
                  <v-text-field
                    v-model="search"
                    v-if="metMudaBusca()"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                    clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="auto"
                  md="4"
                  class="ml-1 mb-0"
                  align-self="start"
                  v-if="!metMudaBusca()"
                >
                  <v-text-field
                    v-if="!metMudaBusca()"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group
                    active-class="primary"
                    show-arrows
                    v-model="selectLista"
                    mandatory
                  >
                    <v-chip
                      key="tarefas"
                      value="tarefas"
                      @click="metMudaLista('tarefas')"
                    >
                      Tarefas
                    </v-chip>
                    <v-chip
                      key="arquivo"
                      value="arquivo"
                      @click="metMudaLista('arquivo')"
                    >
                      Arquivo
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.cria_usuario`]="{ item }">
            <span>{{ obterUsuario(item.cria_usuario) }} </span>
          </template>
          <template v-slot:[`item.responsavel`]="{ item }">
            <span>{{ obterUsuario(item.responsavel) }} </span>
          </template>
          <template v-slot:[`item.observador`]="{ item }">
            <span>{{ obterUsuario(item.observador) }} </span>
          </template>
          <!-- <template v-slot:[`item.prazo`]="{ item }">
            <span
              style="font-size: 80%; white-space: nowrap; display: inline-block"
              >{{ mixDataDBD(item.prazo) }}
            </span>
          </template> -->

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                pegaCorStatus(
                  item.aguardando,
                  item.andamento,
                  item.concluida,
                  item.arquivada
                )
              "
              text-color="white"
              small
            >
              {{
                metStatus(
                  item.aguardando,
                  item.andamento,
                  item.concluida,
                  item.arquivada
                )
              }}
            </v-chip>
          </template>

          <template v-slot:[`item.titulo`]="{ item }">
            <tr>
              <v-icon
                v-if="
                  avisoNova(
                    item.responsavel,
                    item.responAvisoNova,
                    item.observador,
                    item.obsAvisoNova
                  )
                "
                color="orange"
                >mdi-star</v-icon
              >
              <v-icon
                v-if="
                  !avisoNova(
                    item.responsavel,
                    item.responAvisoNova,
                    item.observador,
                    item.obsAvisoNova
                  )
                "
                color="white"
              >
                mdi-checkbox-blank
              </v-icon>
              <v-icon v-if="item.prioridade" color="red">mdi-fire</v-icon>
              <v-icon v-if="!item.prioridade" color="white"
                >mdi-checkbox-blank</v-icon
              >

              <td :class="metTituloDestaque(item.responsavel)">
                {{ item.titulo.slice(0, 40) }}
              </td>

              <v-badge
                v-if="
                  avisoMod(
                    item.cria_usuario,
                    item.criadorAvisoMod,
                    item.responsavel,
                    item.responAvisoMod,
                    item.observador,
                    item.obsAvisoMod
                  )
                "
                color="blue"
                dot
                inline
              >
              </v-badge></tr
          ></template>

          <template v-slot:[`item.tempo`]="{ item }">
            <v-chip
              :color="pegaCorTempo(tempoTarefa(item.cria_datahora))"
              small
              v-if="
                (item.aguardando == 1 || item.andamento == 1) &&
                item.arquivada == 0
              "
            >
              {{ tempoTarefa(item.cria_datahora) }}
            </v-chip>
          </template>
          <template v-slot:[`item.prazo`]="{ item }">
            <v-chip
              :color="pegaCorPrazo(prazoTarefa(item.prazo))"
              text-color="white"
              small
              v-if="
                (item.aguardando == 1 || item.andamento == 1) &&
                item.prazo &&
                item.arquivada == 0
              "
            >
              {{ prazoTarefa(item.prazo) }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-1"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="selecionarTarefaEdicao(item)"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <span
              v-if="
                !item.arquivada &&
                metMostraPar(item.responsavel, item.andamento)
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="pink"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaParar(item)"
                  >
                    mdi-stop-circle-outline
                  </v-icon>
                </template>
                <span>Parar</span>
              </v-tooltip>
            </span>

            <span
              v-if="
                !item.arquivada &&
                metMostraCome(item.responsavel, item.aguardando)
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaComecar(item)"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                </template>
                <span>Começar</span>
              </v-tooltip>
            </span>

            <span
              v-if="
                !item.arquivada &&
                metMostraConc(
                  item.cria_usuario,
                  item.responsavel,
                  item.andamento
                )
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaConcluir(item)"
                  >
                    mdi-checkbox-marked-outline
                  </v-icon>
                </template>
                <span>Concluir</span>
              </v-tooltip>
            </span>

            <span
              v-if="
                !item.arquivada &&
                metMostraAbr(
                  item.cria_usuario,
                  item.responsavel,
                  item.concluida
                )
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaAbrir(item)"
                  >
                    mdi-skip-backward-outline
                  </v-icon>
                </template>
                <span>Abrir</span>
              </v-tooltip>
            </span>

            <span v-if="!item.arquivada && metMostraArq(item.cria_usuario)">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="brown"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaArquivar(item)"
                  >
                    mdi-archive-outline
                  </v-icon>
                </template>
                <span>Arquivar</span>
              </v-tooltip>
            </span>

            <span v-if="item.arquivada && metMostraReciclar(item.cria_usuario)">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="orange darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metTarefaReciclar(item)"
                  >
                    mdi-recycle
                  </v-icon>
                </template>
                <span>Reciclar</span>
              </v-tooltip>
            </span>
            <!-- <confirm-dialogue ref="confirmDialogue"></confirm-dialogue> -->
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
import MailMixin from "@/mixins/MailMixin";

import moment from "moment";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("utilTarefas");

export default {
  name: "UtilTarefasLista",
  mixins: [DatasMixin, MailMixin],
  data: () => ({
    mostraPrazo: false,
    listaCliAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id", align: "center", width: "5%" },
      {
        text: "Titulo",
        align: "start",
        value: "titulo",
        width: "50%",
      },
      { text: "Vida", value: "tempo", align: "center", width: "5%" },
      { text: "Prazo", value: "prazo", align: "center", width: "5%" },
      { text: "Criador", value: "cria_usuario", align: "start", width: "5%" },
      {
        text: "Responsavel",
        value: "responsavel",
        align: "start",
        width: "5%",
      },
      { text: "Observador", value: "observador", align: "start", width: "5%" },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
        width: "5%",
      },
      { text: "Ações", value: "actions", sortable: false, width: "20%" },
    ],

    selectLista: undefined,
  }),

  computed: {
    ...mapState([
      "erro",
      "tarefas",
      "tarefaSelecionado",
      "listaUsuarios",
      "stoListaTar",
    ]),
    ...mapGetters(["getTarefasListar"]),

    metTarefasLista() {
      return this.getTarefasListar(this.$store.state.login.usuario.id);
    },

    tarefasProgress() {
      // console.log(!this.$store.state.utilTarefas.tarefas.length);
      return this.$store.state.utilTarefas.tarefas.length;
      // return this.tarefas;
    },
  },

  created() {
    register(this.$store);
    this.metMudaLista("tarefas");
    this.selectLista = this.stoListaTar;
    this.actListarTarefas(this.$store.state.login.licenca);
    this.listarUsuarios(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterTarefas();
  },

  methods: {
    ...mapActions([
      "actListarTarefas",
      "actListarTarefasArq",
      "actSelecionarTarefa",
      "resetarTarefa",
      "ativarTarefa",
      "listarUsuarios",
      // "desAvisoMod",
      // "desAvisoNova",
      "editarTarefa",
      "actModoListaTar",
    ]),

    metTituloDestaque(resp) {
      if (resp == this.$store.state.login.usuario.id) {
        return "background-color: lime accent-2";
      } else {
        return "";
      }
    },

    metMudaLista(modo) {
      // console.log(modo);
      this.actModoListaTar({ modo });
      this.metRecarregar();
    },

    metMudaBusca() {
      // console.log(this.stoListaTar);
      switch (this.stoListaTar) {
        case "tarefas":
          return true;
        case "arquivo":
          return false;
      }
    },

    metMostraArq(dono) {
      if (dono == this.$store.state.login.usuario.id) {
        return true;
      }
      return false;
    },

    metMostraReciclar(dono) {
      if (dono == this.$store.state.login.usuario.id) {
        return true;
      }
      return false;
    },

    metMostraCome(resp, agua) {
      if (resp == this.$store.state.login.usuario.id && agua == 1) {
        return true;
      }
      return false;
    },

    metMostraPar(resp, and) {
      if (resp == this.$store.state.login.usuario.id && and == 1) {
        return true;
      }
      return false;
    },

    metMostraConc(dono, resp, and) {
      if (
        (dono == this.$store.state.login.usuario.id ||
          resp == this.$store.state.login.usuario.id) &&
        and == 1
      ) {
        return true;
      }
      return false;
    },

    metMostraAbr(dono, resp, conc) {
      if (
        (dono == this.$store.state.login.usuario.id ||
          resp == this.$store.state.login.usuario.id) &&
        conc == 1
      ) {
        return true;
      }
      return false;
    },

    async metTarefaArquivar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.arquivada = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa Arquivada!",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Arquivada"
      );
    },

    async metTarefaReciclar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.arquivada = 0;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa reciclada",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Reciclada"
      );
    },

    async metTarefaComecar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 1;
      tarefaTemp.aguardando = 0;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa iniciada",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Iniciada"
      );
    },

    async metTarefaParar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 0;
      tarefaTemp.aguardando = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa parada",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Parada"
      );
    },

    async metTarefaConcluir(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 0;
      tarefaTemp.concluida = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa concluída",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Concluida"
      );
    },

    async metTarefaAbrir(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.concluida = 0;
      tarefaTemp.aguardando = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa aberta",
      });
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Aberta"
      );
    },

    tempoTarefa(temp) {
      const now = new Date();
      const pass = new Date(temp);
      const diff = Math.abs(now.getTime() - pass.getTime());
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return days;
    },
    prazoTarefa(prazo) {
      const now = new Date();
      const pass = new Date(prazo);
      const diff = pass.getTime() - now.getTime();
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      // console.log(days);
      return days;
    },

    metStatus(agua, anda, conc, arq) {
      if (arq == 1) {
        return "Arquivada";
      } else if (conc == 1) {
        return "Concluida";
      } else if (anda == 1) {
        return "Andamento";
      } else if (agua == 1) {
        return "Aguardando";
      }
      return "Status";
    },

    pegaCorStatus(agua, anda, conc, arq) {
      if (arq == 1) {
        return "blue-grey darken-1";
      } else if (conc == 1) {
        return "green darken-2";
      } else if (anda == 1) {
        return "blue darken-1";
      } else if (agua == 1) {
        return "red darken-2";
      }
      // this.varStatus = "Status";
      return "";
    },

    pegaCorTempo(tempo) {
      if (tempo < 3) {
        return "light-green accent-3";
      } else if (tempo <= 7) {
        return "yellow accent-4";
      } else if (tempo >= 8) {
        return "cyan accent-3";
      }
    },

    pegaCorPrazo(prazo) {
      if (prazo < 0) {
        return "grey";
      } else if (prazo <= 1) {
        return "red";
      } else if (prazo <= 3) {
        return "blue";
      } else if (prazo >= 4) {
        return "green";
      }
    },

    avisoMod(
      criador,
      criadorAvisoMod,
      responsavel,
      responAvisoMod,
      observador,
      obsAvisoMod
    ) {
      if (
        this.$store.state.login.usuario.id == criador &&
        criadorAvisoMod == true
      ) {
        return true;
      } else if (
        this.$store.state.login.usuario.id == responsavel &&
        responAvisoMod == true
      ) {
        return true;
      } else if (
        this.$store.state.login.usuario.id == observador &&
        obsAvisoMod == true
      ) {
        return true;
      }
      return false;
    },

    avisoNova(responsavel, responAvisoNova, observador, obsAvisoNova) {
      if (
        this.$store.state.login.usuario.id == responsavel &&
        responAvisoNova == true
      ) {
        return true;
      } else if (
        this.$store.state.login.usuario.id == observador &&
        obsAvisoNova == true
      ) {
        return true;
      }
      return false;
    },

    formataData(data) {
      return moment(data).utc().format("DD-MM-YYYY");
    },
    formataDataIn(data) {
      return moment(data).utc().format("YYYY-MM-DD");
    },

    ativoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {
      /*       if (this.tarefas === null) {
        this.tarefas = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    novoTarefa() {
      if (this.tarefaSelecionado) {
        this.resetarTarefaSelecionado();
        return;
      }
    },

    selecionarTarefaEdicao(tarefa) {
      this.actSelecionarTarefa({ tarefa });
      this.$router.push({ name: "UtilTarefasForm" });
      // if (tarefa != undefined) {
      //   const usuarioId = this.$store.state.login.usuario.id;
      //   this.desAvisoMod({
      //     tarefa: tarefa,
      //     licenca: this.$store.state.login.licenca,
      //     usuarioId: usuarioId,
      //   });
      //   this.desAvisoNova({
      //     tarefa: tarefa,
      //     licenca: this.$store.state.login.licenca,
      //     usuarioId: usuarioId,
      //   });
      // }
    },

    obterUsuario(id) {
      if (id != null || id != 0) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        if (ret.length != 0) {
          return ret[0].usuario;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    obterEmail(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].email;
      } else {
        const ret = "";
        return ret;
      }
    },

    paraAtivacao(tarefa) {
      // if (tarefa.data_cadastro != "0000-00-00") {
      tarefa.data_cadastro = this.formataDataIn(tarefa.data_cadastro);
      // }
      // if (tarefa.ultima_compra != "0000-00-00") {
      tarefa.ultima_compra = this.formataDataIn(tarefa.ultima_compra);
      // }
      this.ativarTarefa({
        tarefa: tarefa,
        licenca: this.$store.state.login.licenca,
      });
      // this.listarTarefas();
    },
    resetarTarefa() {
      this.resetarTarefaSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      switch (this.stoListaTar) {
        case "tarefas":
          this.actListarTarefas(this.$store.state.login.licenca);
          break;
        case "arquivo":
          this.actListarTarefasArq(this.$store.state.login.licenca);
          break;
      }
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },

    metEmail(id, titulo, emailCria, emailResp, emailObs, acao) {
      this.mixEnviaEmail(
        "Tarefas - Clomos<rotnet@rotnet.com.br",
        emailCria + "," + emailResp + "," + emailObs,
        "",
        "Tarefa " + id + " " + acao,
        "Tarefa: " +
          id +
          " " +
          acao +
          "\n" +
          "Titulo: " +
          titulo +
          "\n" +
          acao +
          " por: " +
          this.$store.state.login.usuario.nome +
          "\n\n" +
          "Clomos"
      );
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
